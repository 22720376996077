:local(.root) {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  position: absolute;
  //pointer-events: none;
}

:local(.background),
:local(.foreground) {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  flex: 1 1 auto;
  position: absolute;
}

:local(.foreground) {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
}

:local(.background) {
  background: rgba(0, 0, 0, .4);
}

:local(.trophy) {
  color: gold;
  flex: 0 0 auto;
  margin: 56px;
}

:local(.newRecordSection) {
  flex: 1 1 auto;
  width: 100%;
  position: relative;
}

:local(.newRecordBanner) {
  background: #c42a00;
  width: 120%;
  margin-left: -10%;
  color: white;
  text-transform: uppercase;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  padding: 16px 0;
  font-style: italic;
  transform: rotate(-6deg);
  white-space: nowrap;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
  box-shadow: 4px 4px 22px rgba(0, 0, 0, .15);
}
