:local(.root) {
  flex: 1 1 auto;
  position: relative;
}

:local(.scrollBox) {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: scroll;
}
