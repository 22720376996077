:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

:local(.backButton),
:local(.editButton) {
  color: white;
  padding: 16px;
  pointer-events: all;
}

:local(.titleSection) {
  flex: 0 0;
  display: flex;
  align-items: center;
  padding: 16px;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.1);
}

:local(.workoutName) {
  font-size: 24px;
  color: black;
  margin-bottom: 4px;
}

:local(.workoutDetails) {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

:local(.rows) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: auto;
  min-height: 0;
}

:local(.row) {
  height: 50px;
  background: white;
  color: black;
  font-size: 20px;
  display: flex;
  flex: 0 0 auto;
  padding: 0 8px;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background: rgba(250, 250, 250, 0.95);
  }
}

:local(.name) {
  flex: 1 0;
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
}

:local(.nameInner) {
  overflow: hidden;
  text-overflow: ellipsis;
}
