:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: #2b2b2b;
}

:local(.title) {
  color: white;
  flex: 0 0 auto;
  font-size: 58pt;
  text-align: center;
  margin: 16px 48px;

  :local(.lift) {
    font-weight: bold;
  }
}

:local(.body) {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 32px;
}

:local(.diagram) {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

:local(.menu) {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

:local(.button) {
  outline: 0;
  height: 80px;
  border-radius: 8px;
  width: calc(100% - 48px);
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.4);
  border: none;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  &:active {
    background: rgba(0, 0, 0, 0.6);
  }
}

:local(.login) {
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

:local(.inputsRows) {
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
  }
}

:local(.loginRow) {
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  button {
    background: #c64f19;
    color: white;
    flex: 1 1 50%;
    margin: 4px;
    font-size: 18px;
    padding: 16px;
  }

  :local(.cancel) {
    background: #414141;
  }
}
