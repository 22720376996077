:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

:local(.titleBar) {
  height: 50px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: black;
  color: white;
  padding: 0 8px;

}

:local(.left),
:local(.right)
{
  color: white;
  flex: 0 0 auto;
  min-width: 20vw;
  pointer-events: all;
  display: flex;

  button {
    font-size: 20px;
    color: white;
  }
}

:local(.right) {
  justify-content: flex-end;
}

:local(.title) {
  font-size: 14pt;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex: 1 1 60vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

:local(.body) {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background: white;

  > * {
    display: flex;
    flex: 1 1 auto;
    pointer-events: all;
    flex-direction: column;
  }
}
