:local(.input) {
  border-bottom: 2px solid #a6a6a6;
  margin-bottom: 32px;
  padding: 8px 0;
  font-size: 18px;

  &:focus {
    border-bottom: 2px solid #c64f19;
  }
}

:local(.label) {
  color: #c64f19;
  font-size: 16px;
}
