:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: white;
  color: white;
  pointer-events: all;
}

:local(.backButton),
:local(.editButton) {
  color: white;
  padding: 16px;
  font-size: 20px;
  pointer-events: all;
}

:local(.logSection) {
  display: flex;
  flex: 1 1 20vh;
  flex-direction: column;

  :local(.logsTitleRow) {
    flex: 0 0 auto;
    color: black;
    background: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, .1);
    margin-bottom: 4px;

    :local(.logsTitleText) {
      font-size: 18px;
      flex: 1 1 auto;
      font-weight: bold;
      padding: 16px;
    }

    :local(.undoText) {
      padding: 16px;
      font-size: 16px;
    }
  }
}

:local(.timerSection) {
  display: flex;
  flex: 0 0 30px;
  position: relative;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  align-items: center;
  justify-content: center;
}

:local(.timerBar) {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: azure;
  transition: all 0.2s;
}

:local(.timerText) {
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

:local(.recordNewSetSection) {
  display: flex;
  flex-direction: column;
  flex: 1 1 20vh;
  white-space: nowrap;

  :local(.statsAndInputsRow) {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;

    :local(.inputsRow) {
      display: flex;
      flex: 0 0 auto;
    }
    input {
      flex: 1 1 auto;
      min-width: 0;
      height: 40px;
      font-size: 20px;
      text-align: center;
    }
  }

  :local(.recordNewPickers) {
    display: flex;
    min-height: 10vh;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }

  :local(.pickerColumn) {
    flex: 1 1 auto;
    background-color: #c1c1c1;
  }

  :local(.recordNewButtonView) {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;

    :local(.oneRepMaxRow) {
      color: black;
      flex: 0 0 30px;
      text-align: center;

      :local(.oneRepMaxTextBold) {
        font-weight: bold;
      }
    }
  }
}

:local(.keyboardButtonView) {
  display: flex;
  flex: 0 0 auto;
  background: black;
}

:local(.recordNewButton) {
  flex: 1 1 auto;
  margin: 8px 16px;
  border: none;
  padding: 16px;
  font-size: 24px;
  background-color: #d44221;
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
}

:local(.warmUpModalView) {
  flex: 0 0 32px;
  background: green;
}
