:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

:local(.body) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: white;
}

:local(.exerciseRow) {
  height: 50px;
  background: white;
  color: black;
  font-size: 20px;
  display: flex;
  flex: 0 0 auto;
  padding: 0 8px;
  cursor: pointer;
  white-space: nowrap;
  pointer-events: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background: rgba(250, 250, 250, 0.95);
  }
}

:local(.exerciseTitle) {
  flex: 1 0;
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
}

:local(.text) {
  overflow: hidden;
  text-overflow: ellipsis;
}

:local(.star) {
  flex: 0 0;
}

:local(.exerciseRecentSets) {
  font-size: 14px;
  opacity: 0.7;
  align-items: center;
  display: flex;
  flex: 0 0;
}

:local(.searchBar) {
  background: black;
  display: flex;
  color: white;
  padding: 4px;
  align-items: center;
}

:local(.searchIcon) {
  flex: 0 0 auto;
  margin: 4px;
  font-size: 18px;
  opacity: 0.5;
}

:local(.searchInputWrapper) {
  flex: 1 1 auto;
  position: relative;
}

:local(.searchInput) {
  flex: 1 1 auto;
  color: #b2b2b2;
  background: #1d1d1d;
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  width: 100%;
}

:local(.searchClear) {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 16px;
  opacity: 0.3;
  padding: 4px;
  transform: translateY(-50%);
}
