:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 16px;
}

:local(.backButton),
:local(.editButton) {
  color: white;
  padding: 16px;
  pointer-events: all;
}

:local(.title) {
  font-size: 18px;
  color: #c42a00;
  margin: 8px 0;
}

:local(.subtitle) {
  font-size: 14px;
  margin: 8px 0;
}

:local(.export) {
  display: flex;
  flex-direction: column;
}

:local(.textarea) {
  display: flex;
  height: 40vh;
}
