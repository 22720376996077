:local(.root) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  overscroll-behavior-y: none;
}

:local(.screen) {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

:local(.drawer) {
  display: flex;
  flex: 0 0 auto;
}

:local(.fullScreen) {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  pointer-events: none;
}
