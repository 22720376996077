:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  background: white;
}

:local(.logsText) {
  display: flex;
  flex: 0 0 auto;
  align-items:flex-end;
  color: rgb(150, 150, 150);
  font-size: 14px;
  margin-bottom: 4px;
  padding: 0 8px;
  white-space: nowrap;
}

:local(.repCount) {
  color: black;
  font-size: 18px;
  min-width: 20px;
}

:local(.weight) {
  color: black;
  font-size: 18px;
  min-width: 26px;
}

:local(.ORMText) {
  color: black;
  font-size: 18px;
}

:local(.logsTextAgo) {
  font-size: 10px;
}
