:local(.root) {
  background: black;
  width: 100%;
  display: flex;
  height: 80px;
}

:local(.item) {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  text-transform: uppercase;
  flex: 1 1 auto;
  border-right: 1px solid #242425;
  cursor: pointer;

  :local(.icon) {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }

  :local(.name) {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }

  &:local(.selected) {
    color: orange;
  }
  &:last-child {
    border: none;
  }
}
