:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: black;
}

:local(.workoutRow) {
  height: 50px;
  background: white;
  color: black;
  font-size: 24px;
  display: flex;
  flex: 0 0 auto;
  padding: 0 8px;
  cursor: pointer;
  justify-content: space-between;
  pointer-events: all;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  &:hover {
    background: rgba(250, 250, 250, .95);
  }
}

:local(.workoutTitle) {
  display: flex;
  align-items: center;
}

:local(.workoutExerciseCount) {
  display: flex;
  align-items: center;
  font-size: 18px;
  opacity: 0.7;
}
