:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background-color: white;

  button {
    background: black;
    color: white;
    padding: 32px;
    margin: 16px;
    font-size: 20px;
  }
}

:local(.version) {
  opacity: 0.5;
  margin: 16px;
  text-align: right;
  align-self: flex-end;
}
