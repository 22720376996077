:local(.root) {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

:local(.items) {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}

:local(.itemRow) {
  flex: 0 0 auto;
  font-size: 20px;
  height: 30px;
  color: black;
  text-align: center;
}

:local(.centerline) {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 30px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}

:local(.padding) {
  flex: 0 0 auto;
}
