:local(.root) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0 16px;
  margin-top: 32px;
  pointer-events: all;
}

:local(.buttonRow) {
  flex: 0 0 auto;
  display: flex;

  button {
    background: #c64f19;
    color: white;
    flex: 1 1 50%;
    margin: 4px;
    font-size: 18px;
    padding: 16px;
  }

  :local(.cancel) {
    background: #414141;
  }
}
