body, html, #root {
  overflow: hidden;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

* {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button, input {
  background: none;
  border: none;
  &:focus {
    outline: 0;
  }
}

textarea {
  outline: none;
  border-radius: 4px;
  border: 1px solid darkgrey;
  box-shadow: inset 0 1px 6px 0 rgba(32,33,36,0.28);

  &:focus {
    border: 1px solid #c42a00;
  }
}

[data-scrollable] {
  -webkit-overflow-scrolling: touch;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
